import {
  Button,
  Center,
  CenterProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Square,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import { DocumentTypeEnum } from '@/graphql/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { datadogLogs } from '@datadog/browser-logs';
import { useEffect, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FaFileLines } from 'react-icons/fa6';
import { MdCameraAlt } from 'react-icons/md';
import { FileUploadProps, useFileUploadHandler } from '.';
import SimpleLoading from '../SimpleLoading';

type FileUploadZoneProps = FileUploadProps & CenterProps;

type LinkDefaultDataType = {
  url: string | undefined;
  name: string | undefined;
};

const defaultValues: LinkDefaultDataType = {
  name: undefined,
  url: undefined,
};

const FileUploadZone = ({
  acceptTypes,
  isMultiple = false,
  addFile,
  addFiles,
  getFileUploadUrls,
  ...centerProps
}: FileUploadZoneProps) => {
  const { isDesktop } = useScreenInfos();
  const { t, t_errors } = useTranslation();

  const { acceptArray, label, handleFilesChange, handleDrop, isUploading, resizeModal } =
    useFileUploadHandler({
      isMultiple,
      acceptTypes,
      addFile,
      addFiles,
      getFileUploadUrls,
    });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLElement | null>(null);
  const {
    isOpen: isAddLinkModalOpen,
    onOpen: onOpenAddLinkModal,
    onClose: onCloseAddLinkModal,
  } = useDisclosure();
  const {
    isOpen: isSelectFileOrLinkModalOpen,
    onOpen: onOpenSelectFileOrLinkModal,
    onClose: onCloseSelectFileOrLinkModal,
  } = useDisclosure();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<LinkDefaultDataType>({
    defaultValues,
  });

  const handleClick = () => {
    if (acceptTypes && acceptTypes.includes('link')) {
      onOpenSelectFileOrLinkModal();
      return;
    }
    handleInputClick();
  };

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current?.click();
    } else {
      datadogLogs.logger.error('failed to show upload dialog');
    }
    // ここの順番を変えると、下のモーダルが消えない
    onCloseSelectFileOrLinkModal();
  };

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const dropZone = containerRef.current;
    if (dropZone === null) throw new Error(t_errors('drop-zone-not-exist'));
    dropZone.addEventListener('dragover', handleDragOver);
    dropZone.addEventListener('drop', handleDrop);

    return () => {
      dropZone.removeEventListener('dragover', handleDragOver);
      dropZone.removeEventListener('drop', handleDrop);
    };
  }, [handleDrop, t_errors]);

  const handleShowUrlModal = () => {
    onCloseSelectFileOrLinkModal();
    onOpenAddLinkModal();
    setValue('name', '');
    setValue('url', '');
  };

  const handleCancel = () => {
    onCloseAddLinkModal();
    setValue('name', '');
    setValue('url', '');
  };

  const onSubmit = (values: FieldValues) => {
    const isValidURL = validateURL(values.url);
    if (isValidURL) {
      onCloseAddLinkModal();
      setValue('name', '');
      setValue('url', '');
      addFile &&
        addFile({
          file: { name: values.name } as File,
          url: values.url,
          type: DocumentTypeEnum.Link,
        });
    }
  };

  const validateURL = (value: string) => {
    try {
      new URL(value);
      return true;
    } catch (_error) {
      setError('url', { message: 'Please enter a valid URL.' });
    }
  };

  return (
    <Center
      borderWidth='1px'
      borderRadius='lg'
      bg='white'
      {...centerProps}
      backgroundColor='primary.50'
      position='relative'
      ref={(e) => {
        containerRef.current = e;
      }}
    >
      {isUploading && <SimpleLoading />}
      <VStack spacing={0} onClick={handleClick} px='6' py='4' width='100%'>
        <Input
          accept={acceptArray.join(',')}
          type='file'
          w={0}
          h={0}
          overflow='hidden'
          border='none'
          display='none'
          onChange={(e) => handleFilesChange(e)}
          onClick={(event): string => (event.currentTarget.value = '')}
          ref={(e) => {
            inputRef.current = e;
          }}
          multiple={isMultiple}
        />
        <Square size={{ base: '1', md: '5' }} borderRadius='lg' mb={3}>
          <Icon
            as={acceptArray.length > 3 ? FaFileLines : MdCameraAlt}
            boxSize='6'
            color='primary.500'
            paddingBottom={acceptArray.length > 3 ? '2px' : '0px'}
          />
        </Square>
        <VStack spacing='1'>
          <HStack spacing='1' whiteSpace='nowrap'>
            <Button variant='link' colorScheme='primary' size='sm'>
              {acceptArray.length > 3
                ? t('actions.add-files', { acceptTypes: t('form.select.file') })
                : t('actions.add-files', { acceptTypes: label })}
            </Button>
            {isDesktop && (
              <Text fontSize='sm' color='gray.500'>
                {`${t('or')} ${t('actions.drag-and-drop')}`}
              </Text>
            )}
          </HStack>
        </VStack>
      </VStack>
      <Modal isOpen={isSelectFileOrLinkModalOpen} onClose={onCloseSelectFileOrLinkModal} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('actions.add-files', { acceptTypes: t('form.select.file') })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5} borderTop='1px' borderColor='neutral.300' bg='neutral.0'>
            <Flex width='full' left='0' py='2' px='4' direction='column' gap={3}>
              <Button colorScheme='primary' width='auto' onClick={handleInputClick}>
                {t('actions.upload-files', { acceptTypes: t('form.select.file') })}
              </Button>
              <Button colorScheme='primary' width='auto' onClick={handleShowUrlModal}>
                {t('actions.add-url-link')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddLinkModalOpen}
        size={{ base: 'full', md: 'lg' }}
        onClose={() => onCloseAddLinkModal()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('actions.add-files', { acceptTypes: t('link') })}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5} borderTop='1px' borderColor='neutral.300' bg='neutral.0'>
            <FormControl
              bg='neutral.0'
              borderRadius='md'
              mb={4}
              width='auto'
              isInvalid={!!errors.name}
            >
              <FormLabel htmlFor='title'>{t('name')}</FormLabel>
              <Input
                id='title'
                {...register('name', { required: t('warning.this-is-required') })}
              />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb='4' isInvalid={!!errors.url}>
              <FormLabel>{t('link')}</FormLabel>
              <Input
                pattern='https://.*'
                id='link'
                {...register('url', { required: t('warning.this-is-required') })}
                type='url'
              />
              <FormErrorMessage>{errors.url && errors.url.message}</FormErrorMessage>
            </FormControl>
            <Flex
              justifyContent='flex-end'
              width='full'
              left='0'
              py='2'
              px='4'
              backgroundColor='neutral.0'
              zIndex={101}
              height={{ base: 'auto', md: '54px' }}
            >
              <Button variant='outline' colorScheme='primary' width='auto' onClick={handleCancel}>
                {t('actions.cancel')}
              </Button>

              <Button
                ml='2'
                colorScheme='primary'
                type='submit'
                width='100px'
                onClick={handleSubmit(onSubmit)}
              >
                {t('actions.save')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {resizeModal}
    </Center>
  );
};

export default FileUploadZone;
